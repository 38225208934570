.formLabel {
    font-size: 15px;
    font-weight: 700;
}

.emailInputError, .userRoleInputError, .passwordInputError {
    color: #d30505;
}

.error {
    color: #d30505;
    font-size: 11px;
    text-align: left;
    margin-top: 10px;
}   