

.carIcon{
    position: relative;
}

.carArrow{
    position: absolute;
    height: 25px;
    width: 100%;
    right: 0;
    -webkit-animation: shake-horizontal 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
    animation: shake-horizontal 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

.carArrow::before{
    content : "";
    position: absolute;
    width: 3px;
    right: -10px;
    bottom : 4px;
    height: 10px;
    background-color: rgb(163 230 53);
    transform: rotate(45deg);
}
.carArrow::after{
    content : "";
    top : 5px;
    right: -10px;
    width: 3px;
    background-color: rgb(163 230 53);
    height: 10px;
    position: absolute;
    transform: rotate(135deg);
}

  @keyframes shake-horizontal {
    0%{
        padding-left: 0px;
    }
    50%{
        padding-left: 3px;

    }
    100% {
        padding-left: 0px;
    }
  }

  