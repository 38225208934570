.logoIga:hover {
  transform: scale(3);
}

.formLabel {
  font-size: 16px;
  font-weight: 700;
}

.companyNameInputError,
.companyPhoneInputError,
.companyEmailInputError {
  border-color: #d30505;
}

.error {
  color: #d30505;
  font-size: 11px;
  text-align: left;
  margin-top: 10px;
}
