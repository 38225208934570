@media only screen and (max-width: 760px) {

    .ms-2 {
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
    }

    li {
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 35px;
    }

    #show-side-navigation1 {
        width: 70px;
    }

    .navbar.navbar-expand-md {
        width: 100%;
    }

    .container-fluid {
        width: 100%;
        padding-left: 70px;
    }
}