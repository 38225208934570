.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 10px;
  /* From https://css.glass */
  background: rgba(221, 219, 219, 0.23);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(221, 219, 219, 1);
  color: #fff !important;
}

.box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.box .inputBox {
  position: relative;
}

.box .inputBox input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  letter-spacing: 2px;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.box .inputBox label {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: 2px;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.box .inputBox input:focus~label,
.box .inputBox input:valid~label {
  top: -25px;
  left: 0;
  color: #000;
  font-size: 12px;
  font-weight: bold;
}

.box input[type="submit"] {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  background: #000;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}



/* 
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */



body {
  margin: 0;
}

.bg-light {
  background: #fff !important;
}

.card {
  background-color: rgba(255, 255, 255, 0.7);
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 5%;
}

.logo img {
  width: 100%;
}



h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 48px;
}

h2 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 48px;
}

p {
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #333333;
}

.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  text-align: center;
  /* ff 3.6+ */
  background: -moz-radial-gradient(circle at 50% 50%, rgba(244, 67, 54, 1) 0%, rgba(93, 6, 0, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(circle at 50% 50%, rgba(244, 67, 54, 1) 0%, rgba(93, 6, 0, 1) 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(circle at 50% 50%, rgba(244, 67, 54, 1) 0%, rgba(93, 6, 0, 1) 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(circle at 50% 50%, rgba(244, 67, 54, 1) 0%, rgba(93, 6, 0, 1) 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(circle at 50% 50%, rgba(244, 67, 54, 1) 0%, rgba(93, 6, 0, 1) 100%);

  color: white;
}


.inner-header {
  height: 80vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flexx {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 20vh;
  /* margin-bottom: -7px; */
  /*Fix for safari gap*/
  /* min-height: 100px;
  max-height: 150px; */
}



/* Animation */

.parallax>use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax>use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax>use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}


.glass {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  h1 {
    font-size: 24px;
  }
}