@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500;600;700&display=swap');
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Inter', sans-serif,
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--dk-gray-300);
	font-size: .825rem;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.root,
#root,
#docs-root {
	--pipe-color: #073d70;
}

:root {
	--dk-gray-100: #F3F4F6;
	--dk-gray-200: #E5E7EB;
	--dk-gray-300: #D1D5DB;
	--dk-gray-400: #9CA3AF;
	--dk-gray-500: #969696;
	--dk-gray-600: #4B5563;
	--dk-gray-700: #374151;
	--dk-gray-800: #1F2937;
	--dk-gray-900: #004df3;
	--dk-dark-bg: #313348;
	--dk-darker-bg: #2a2b3d;
	--navbar-bg-color: #8b8b8bbb;
	--sidebar-bg-color: #8b0400;
	--sidebar-width: 220px;
}

/* body:not(.leaflet-container) {
	filter: invert(1);
} */

.MuiOutlinedInput-root {
	padding: 0 !important;
}

.MuiAutocomplete-root .MuiInputLabel-root {
	top: -7px !important;
}

.dpicker input {
	padding: 7.5px 14px !important;
}

.dpicker button.MuiIconButton-edgeEnd {
	padding: 4px !important;
	margin-right: 1px !important;
}

.MuiSelect-select {
	padding: 7.5px 14px !important;
}

.navbar.navbar-expand-md {
	top: 0 !important
}

.content {
	width: calc(100% - 220px);
	margin-left: 220px;
}

@media only screen and (max-width : 768px){
	.content{
		width: calc(100% - 70px);
		display: flex;
		margin-left: 70px;
		justify-content: center;
	}
}

.contentt {
	width: calc(100% - 620px);
	justify-content: center;
	border-radius: 8px;

}

.wrapper {
	background-color: #f4f6f9;
	/* border-radius: 8px; */
	padding: 1.25rem 2rem;
}

.leaflet-popup-content-wrapper {
	background-color: rgba(14, 141, 201, 0.7) !important;
	color: #fff;
	border-radius: 8px !important;
}

.truck-icon {
	fill: red;
}

.custom-head { color: rgb(13,109,253); border-bottom: 2px solid rgb(13,109,255); }

/* -------------------------- LAYOUT START --------------------------- */
#wrapper {
	margin-left: var(--sidebar-width);
	transition: all .3s ease-in-out;
}

#wrapper.fullwidth {
	margin-left: 0;
}

.bounce {
	animation: bounce 0.4s infinite;
}

@keyframes bounce {

	0%,
	100% {
		transform: translateY(-5%);
		animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
	}

	50% {
		transform: translateY(0);
		animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
	}
}

/** --------------------------------
 -- Sidebar
-------------------------------- */
.sidebar {
	background-color: var(--sidebar-bg-color);
	width: var(--sidebar-width);
	transition: all .3s ease-in-out;
	transform: translateX(0);
	z-index: 9999999
}

.sidebar .close-aside {
	position: absolute;
	top: 7px;
	right: 7px;
	cursor: pointer;
	color: #EEE;
}

.sidebar .sidebar-header {
	border-bottom: 1px solid #2a2b3c
}

.sidebar .sidebar-header h5 a {
	color: var(--dk-gray-300)
}

.sidebar .sidebar-header p {
	color: var(--dk-gray-400);
	font-size: .825rem;
}

.sidebar .search .form-control~i {
	color: #2b2f3a;
	right: 40px;
	top: 22px;
}

.sidebar>ul>li {
	padding: 1rem 1.75rem;
}

.sidebar>ul>li:hover {
	background-color: rgba(184, 184, 184, 0.301);
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5.4px);
	-webkit-backdrop-filter: blur(5.4px);
}

.sidebar>ul>li:hover a {
	color: #fff;
}


.sidebar ul>li>a,
.sidebar>ul>li {
	color: var(--dk-gray-400);
}

.sidebar ul>li>a,
.sidebar>ul>li {
	color: var(--dk-gray-200);
	text-decoration: none;
}

/* Start numbers */
.sidebar ul>li>a>.num {
	line-height: 0;
	border-radius: 3px;
	font-size: 14px;
	padding: 0px 5px
}

.sidebar ul>li>i {
	font-size: 18px;
	margin-right: .7rem;
	color: var(--dk-gray-500);
}

.sidebar ul>li.has-dropdown>a:after {
	content: '\2192';
	transform: rotate(90);
	font-family: unicons-line;
	font-size: 1rem;
	line-height: 1.8;
	float: right;
	color: var(--dk-gray-500);
	transition: all .3s ease-in-out;
}

.sidebar ul .opened>a:after {
	transform: rotate(90deg);
}

/* Start dropdown menu */
.sidebar ul .sidebar-dropdown {
	padding-top: 10px;
	padding-left: 1rem;
	display: none;
}

.sidebar ul .sidebar-dropdown.active {
	display: block;
}

.sidebar ul .sidebar-dropdown>li>a {
	font-size: .85rem;
	padding: .5rem 0;
	display: block;
}

/* End dropdown menu */

.show-sidebar {
	transform: translateX(-270px);
}

@media (max-width: 767px) {
	.sidebar ul>li {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.sidebar .search {
		padding: 10px 0 10px 30px
	}
}




/** --------------------------------
 -- welcome
-------------------------------- */
.welcome {
	color: var(--dk-gray-300);
}

.welcome .content {
	background-color: var(--dk-dark-bg);
}

.welcome p {
	color: var(--dk-gray-400);
}




/** --------------------------------
 -- Statistics
-------------------------------- */
.statistics {
	color: var(--dk-gray-200);
}

.statistics .box {
	background-color: var(--dk-dark-bg);
}

.statistics .box i {
	width: 60px;
	height: 60px;
	line-height: 60px;
}

.statistics .box p {
	color: var(--dk-gray-400);
}




/** --------------------------------
 -- Charts
-------------------------------- */
.charts .chart-container {
	background-color: var(--dk-dark-bg);
}

.charts .chart-container h3 {
	color: var(--dk-gray-400)
}




/** --------------------------------
 -- users
-------------------------------- */
.admins .box .admin {
	background-color: var(--dk-dark-bg);
}

.admins .box h3 {
	color: var(--dk-gray-300);
}

.admins .box p {
	color: var(--dk-gray-400)
}




/** --------------------------------
 -- statis
-------------------------------- */
.statis {
	color: var(--dk-gray-100);
}

.statis .box {
	position: relative;
	overflow: hidden;
	border-radius: 3px;
}

.statis .box h3:after {
	content: "";
	height: 2px;
	width: 70%;
	margin: auto;
	background-color: rgba(255, 255, 255, 0.12);
	display: block;
	margin-top: 10px;
}

.statis .box i {
	position: absolute;
	height: 70px;
	width: 70px;
	font-size: 22px;
	padding: 15px;
	top: -25px;
	left: -25px;
	background-color: rgba(255, 255, 255, 0.15);
	line-height: 60px;
	text-align: right;
	border-radius: 50%;
}

.main-color {
	color: #ffc107
}

.boxColor {
	background-color: rgba(255, 255, 255, 0.4);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	font-weight: bold;
}

.gaugeDiv {
	background-color: rgba(255, 255, 255, 0.4);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	padding: 20px;
	margin-left: 2.7%;
	font-weight: bold;
}

/** --------------------------------
 -- Please don't do that in real-world projects!
 -- overwrite Bootstrap variables instead.
-------------------------------- */

.navbar {
	padding: unset !important;
	border: none !important;
	position: fixed !important;
	background: rgba(255, 255, 255, 0.27);
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5.4px);
	-webkit-backdrop-filter: blur(5.4px);
	z-index: 999;
	width: calc(100% - 220px);
}

.navbar .dropdown-menu {
	right: auto !important;
	left: 0 !important;
}

.navbar .navbar-nav>li>a {
	color: #EEE !important;
	line-height: 55px !important;
	padding: 0 10px !important;
}

.navbar .navbar-brand {
	color: #FFF !important
}

.navbar .navbar-nav>li>a:focus,
.navbar .navbar-nav>li>a:hover {
	color: #EEE !important
}

.navbar .navbar-nav>.open>a,
.navbar .navbar-nav>.open>a:focus,
.navbar .navbar-nav>.open>a:hover {
	background-color: transparent !important;
	color: #FFF !important
}

.navbar .navbar-brand {
	line-height: 55px !important;
	padding: 0 !important
}

.navbar .navbar-brand:focus,
.navbar .navbar-brand:hover {
	color: #FFF !important
}

.navbar>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand {
	margin: 0 !important
}

@media (max-width: 767px) {
	.navbar>.container-fluid .navbar-brand {
		margin-left: 15px !important;
	}

	.navbar .navbar-nav>li>a {
		padding-left: 0 !important;
	}

	.navbar-nav {
		margin: 0 !important;
	}

	.navbar .navbar-collapse,
	.navbar .navbar-form {
		border: none !important;
	}
}

.navbar .navbar-nav>li>a {
	float: left !important;
}

.navbar .navbar-nav>li>a>span:not(.caret) {
	background-color: #e74c3c !important;
	border-radius: 50% !important;
	height: 25px !important;
	width: 25px !important;
	padding: 2px !important;
	font-size: 11px !important;
	position: relative !important;
	top: -10px !important;
	right: 5px !important
}

.dropdown-menu>li>a {
	padding-top: 5px !important;
	padding-right: 5px !important;
}

.navbar .navbar-nav>li>a>i {
	font-size: 18px !important;
}




/* Start media query */

@media (max-width: 767px) {
	#wrapper {
		margin: 0 !important
	}

	.statistics .box {
		margin-bottom: 25px !important;
	}

	.navbar .navbar-nav .open .dropdown-menu>li>a {
		color: #CCC !important
	}

	.navbar .navbar-nav .open .dropdown-menu>li>a:hover {
		color: #FFF !important
	}

	.navbar .navbar-toggle {
		border: none !important;
		color: #EEE !important;
		font-size: 18px !important;
	}

	.navbar .navbar-toggle:focus,
	.navbar .navbar-toggle:hover {
		background-color: transparent !important
	}
}


::-webkit-scrollbar {
	background: transparent;
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-thumb {
	background-color: #3c3f58;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0, 0, 0, 0.3);
}

/* -------------------------- LAYOUT END --------------------------- */


.leaflet-control-container .leaflet-top.leaflet-left {
	display: none;
}

.leaflet-bottom.leaflet-right {
	display: none;
}

.login-card-body .input-group .form-control,
.register-card-body .input-group .form-control {
	border-right: 0;
}

.login-card-body .input-group .form-control:focus,
.register-card-body .input-group .form-control:focus {
	box-shadow: none;
}

.login-card-body .input-group .form-control.is-valid:focus,
.register-card-body .input-group .form-control.is-valid:focus {
	box-shadow: none;
}

.login-card-body .input-group .form-control.is-valid:focus,
.register-card-body .input-group .form-control.is-valid:focus {
	box-shadow: none;
}

.login-card-body .input-group .form-control.is-invalid:focus,
.register-card-body .input-group .form-control.is-invalid:focus {
	box-shadow: none;
}

.form-inline .input-group {
	width: auto;
}

.form-inline .input-group {
	width: 100%;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}


.input-group-append .btn,
.input-group-prepend .btn {
	position: relative;
	z-index: 2;
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
	z-index: 3;
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
	margin-left: -1px;
}

.input-group-append .btn-navbar {
	background-color: #495057;
	border-color: #6c757d !important;
	color: #ced4da;
}

.btn-sidebar,
.form-control-sidebar {
	background-color: #3f474e;
	border: 1px solid #56606a;
	color: #fff;
}

.btn-sidebar:focus,
.form-control-sidebar:focus {
	border: 1px solid #7a8793;
}

.btn-sidebar:hover {
	background-color: #454d55;
}

.btn-sidebar:focus {
	background-color: #4b545c;
}

.fa-fw {
	text-align: center;
	width: 1.25em;
}

.form-inline .input-group {
	width: 100%;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.form-inline {
	margin-bottom: 0.2rem;
}

.form-inline .input-group {
	width: auto;
}

.input-group .form-control {
	border-right: 0;
}

.input-group .form-control:focus {
	box-shadow: none;
}

.input-group-append .btn-navbar {
	background-color: #d3d9df;
	border-color: #c7ced5 !important;
	color: #ced4da;
}