.small-box {
    border-radius: 0.25rem;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    display: block;
    margin-bottom: 20px;
    position: relative;
}

.small-box>.inner {
    padding: 10px;
}

.small-box>.small-box-footer {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.8);
    display: block;
    padding: 3px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    z-index: 10;
}

.small-box>.small-box-footer:hover {
    background-color: rgba(0, 0, 0, 0.15);
    color: #fff;
}

.small-box h3 {
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0;
    white-space: nowrap;
}

@media (min-width: 992px) {

    .col-lg-2 .small-box h3,
    .col-md-2 .small-box h3,
    .col-xl-2 .small-box h3 {
        font-size: 1.6rem;
    }

    .col-lg-3 .small-box h3,
    .col-md-3 .small-box h3,
    .col-xl-3 .small-box h3 {
        font-size: 1.6rem;
    }
}

@media (min-width: 1200px) {

    .col-lg-2 .small-box h3,
    .col-md-2 .small-box h3,
    .col-xl-2 .small-box h3 {
        font-size: 2.2rem;
    }

    .col-lg-3 .small-box h3,
    .col-md-3 .small-box h3,
    .col-xl-3 .small-box h3 {
        font-size: 2.2rem;
    }
}

.small-box p {
    font-size: 1rem;
    color: #f8f9fa;
    font-weight: bold;
}

.small-box p>small {
    color: #f8f9fa;
    display: block;
    font-size: 0.9rem;
    margin-top: 5px;
}

.small-box h3,
.small-box p {
    z-index: 5;
}

.small-box .icon {
    color: rgba(0, 0, 0, 0.15);
    z-index: 0;
}

.small-box .icon>i {
    font-size: 90px;
    position: absolute;
    right: 15px;
    top: 0px;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}

.small-box .icon>i.fa,
.small-box .icon>i.fab,
.small-box .icon>i.fad,
.small-box .icon>i.fal,
.small-box .icon>i.far,
.small-box .icon>i.fas,
.small-box .icon>i.ion {
    font-size: 70px;
    top: 0px;
}

.small-box .icon svg {
    font-size: 70px;
    position: relative;
    /* right: 15px;
    top: 15px; */
    align-items: center;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}

.small-box:hover {
    text-decoration: none;
}

.small-box:hover .icon>i,
.small-box:hover .icon>i.fa,
.small-box:hover .icon>i.fab,
.small-box:hover .icon>i.fad,
.small-box:hover .icon>i.fal,
.small-box:hover .icon>i.far,
.small-box:hover .icon>i.fas,
.small-box:hover .icon>i.ion {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.small-box:hover .icon>svg {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

@media (max-width: 767.98px) {
    .small-box {
        text-align: center;
    }

    .small-box .icon {
        display: none;
    }

    .small-box p {
        font-size: 12px;
    }
}









.bg-info {
    background-color: #17a2b8 !important;
}

.bg-info,
.bg-info>a {
    color: #fff !important;
}

.bg-info.btn:hover {
    border-color: #117a8b;
    color: #ececec;
}

.bg-info.btn.active,
.bg-info.btn:active,
.bg-info.btn:not(:disabled):not(.disabled).active,
.bg-info.btn:not(:disabled):not(.disabled):active {
    background-color: #117a8b !important;
    border-color: #10707f;
    color: #fff;
}





.bg-success,
.bg-success>a {
    color: #fff !important;
}

.bg-success.btn:hover {
    border-color: #1e7e34;
    color: #ececec;
}

.bg-success.btn.active,
.bg-success.btn:active,
.bg-success.btn:not(:disabled):not(.disabled).active,
.bg-success.btn:not(:disabled):not(.disabled):active {
    background-color: #1e7e34 !important;
    border-color: #1c7430;
    color: #fff;
}

.bg-info {
    background-color: #17a2b8 !important;
}

.bg-info,
.bg-info>a {
    color: #fff !important;
}

.bg-info.btn:hover {
    border-color: #117a8b;
    color: #ececec;
}

.bg-info.btn.active,
.bg-info.btn:active,
.bg-info.btn:not(:disabled):not(.disabled).active,
.bg-info.btn:not(:disabled):not(.disabled):active {
    background-color: #117a8b !important;
    border-color: #10707f;
    color: #fff;
}

.bg-warning {
    background-color: #ffc107 !important;
}

.bg-warning,
.bg-warning>a {
    color: #1f2d3d !important;
}

.bg-warning.btn:hover {
    border-color: #d39e00;
    color: #121a24;
}

.bg-warning.btn.active,
.bg-warning.btn:active,
.bg-warning.btn:not(:disabled):not(.disabled).active,
.bg-warning.btn:not(:disabled):not(.disabled):active {
    background-color: #d39e00 !important;
    border-color: #c69500;
    color: #1f2d3d;
}

.bg-danger {
    background-color: #dc3545 !important;
}

.bg-danger,
.bg-danger>a {
    color: #fff !important;
}

.bg-danger.btn:hover {
    border-color: #bd2130;
    color: #ececec;
}

.bg-danger.btn.active,
.bg-danger.btn:active,
.bg-danger.btn:not(:disabled):not(.disabled).active,
.bg-danger.btn:not(:disabled):not(.disabled):active {
    background-color: #bd2130 !important;
    border-color: #b21f2d;
    color: #fff;
}