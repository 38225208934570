#chartdiv {
    width: 100%;
    height: 500px;
  }
#linechartdiv {
    width: 100%;
    height: 500px;
  }


  .calendar-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .my-custom-button-style {
    width: 100%;
  }

  .dpicker input {
    padding: 7.5px 14px !important;
}
  