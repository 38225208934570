.singlebattery {
  width: 145px;
  height: 85px;
  background-color: #6c757d;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: relative;
  margin-top: 10px;
  border: 1px double #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.singlebattery span {
  color: #000;
}

.singlebattery:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.pulsate-fwd {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
  animation: pulsate-fwd 0.5s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.formLabel {
  font-size: 16px;
  font-weight: 700;
}

.deviceImeiInputError,
.deviceSimInputError,
.deviceGroupIdInputError,
.deviceTypeInputError {
  border-color: #d30505;
}

.error {
  color: #d30505;
  font-size: 11px;
  text-align: left;
  margin-top: 10px;
}

/* .singlebattery::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 7%;
    width: 25px;
    height: 8px;
    background-color: #000;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

}

.singlebattery::after {
    content: "";
    position: absolute;
    top: -8px;
    right: 7%;
    width: 25px;
    height: 8px;
    background-color: #000;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
} */
