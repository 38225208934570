.vehicleList-container {
  background-color: white;
  border-radius: 30px;
  width: 100%;
  align-items: center;
  text-align: center;
  margin-left: 300px;
}

.formLabel {
  font-size: 15px;
  font-weight: 700;
}

.imeiNoInputError,
.vehicleImeiInputError,
.vehiclePlateInputError,
.vehicleNameInputError,
.vehicleBrandInputError,
.vehicleModelInputError,
.storageSizeSizeInputError {
  color: #d30505;
}

.error {
  color: #d30505;
  font-size: 11px;
  text-align: left;
  /* margin-top: 0px; */
  margin-bottom: 5px;
}
